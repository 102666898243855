import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase';
import { doc, getDoc, updateDoc, increment, setDoc, deleteDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const Likes = ({ postId }) => {
  const [likes, setLikes] = useState(0);
  const [user, setUser] = useState(null);
  const [hasUserLiked, setHasUserLiked] = useState(false);

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const docRef = doc(db, 'likes', postId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLikes(docSnap.data().count);
        } else {
          await setDoc(docRef, { count: 0 });
        }

        if (user) {
          const userLikeDocRef = doc(db, 'likes', postId, 'userLikes', user.uid);
          const userLikeSnap = await getDoc(userLikeDocRef);
          setHasUserLiked(userLikeSnap.exists());
        } else {
          setHasUserLiked(false); // Reset like status if no user
        }
      } catch (error) {
        console.error("Error fetching likes: ", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchLikes();
      } else {
        setHasUserLiked(false); // Reset like status if user signs out
      }
    });

    return () => unsubscribe();
  }, [postId, user]);

  const handleLike = async () => {
    if (!user) {
      alert('You must be signed in to like a post.');
      return;
    }

    const likeDocRef = doc(db, 'likes', postId);
    const userLikeDocRef = doc(db, 'likes', postId, 'userLikes', user.uid);

    try {
      const userLikeSnap = await getDoc(userLikeDocRef);
      if (userLikeSnap.exists()) {
        // User has already liked the post, so unlike it
        await updateDoc(likeDocRef, {
          count: increment(-1)
        });
        await deleteDoc(userLikeDocRef); // Delete user like
      } else {
        // User has not liked the post yet, so like it
        await updateDoc(likeDocRef, {
          count: increment(1)
        });
        await setDoc(userLikeDocRef, { liked: true });
      }

      const likeSnap = await getDoc(likeDocRef);
      setLikes(likeSnap.data().count);
      setHasUserLiked(!userLikeSnap.exists());
    } catch (error) {
      console.error("Error updating likes: ", error);
    }
  };

  return (
    <i
      className={`fas fa-heart ${hasUserLiked ? 'liked' : ''}`}
      onClick={handleLike}
    ></i>
  );
};

export default Likes;
