import React, { useState } from 'react';
import { TextInput, Title, Button } from '@mantine/core';
import emailjs from 'emailjs-com';

function ContactForm({ onClose, handleSendMessage }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm('service_iu8s11a', 'template_5qsg8eq', e.target, 'Ubvuq6P8uPurCN1CM')
      .then((result) => {
        console.log(result.text);
        handleSendMessage(true);
        onClose(); // Close modal on successful submission
      }, (error) => {
        console.log(error.text);
      });

      setFormData({
        name: '',
        email: '',
        message: ''
      });
  };

  return (
    <form className="form contact-form reddit-mono-400" onSubmit={handleSubmit}>
      <TextInput
        label="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <TextInput
        label="email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <TextInput
        label="message"
        name="message"
        multiline
        value={formData.message}
        onChange={handleChange}
        required
      />
      <Button type="submit" variant="outline">send</Button>
    </form>
  );
}

export default ContactForm;
