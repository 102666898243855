const allPosts = [
    {
        id: '06-22-24',
        date: '06-22-24',
        title: "Confidence",
        excerpt: `<p className="quote">Lack of confidence is the heaviest anchor an athlete can wear.<br />–Dave Spitz, Owner, California Strength</p><p>Spend time with people who lift you up.</p>`,
        content: `<p className="quote">Lack of confidence is the heaviest anchor an athlete can wear.<br />–Dave Spitz, Owner, California Strength</p><p>Spend time with people who lift you up.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/z27os42pa38ukzcikp4zr/basketball.jpg?rlkey=mhh9bavg2w8hx26peqsfkp6zo&st=v08w2gbj&raw=1",
        tags: ["relationships"]
    },
    {
        id: '06-21-24',
        date: '06-21-24',
        title: "Lunch",
        excerpt: `<p>A go-to lunch</p><ul>
            <li>Trader Joe's pre-made fajita chicken</li>
            <li>Almond flour tortilla</li>
            <li>Trader Joe's mixed cabbage greens</li>
            <li>Pico de gallo</li>
            <li>Hummus</li>
        </ul>`,
        content: `<p>A go-to lunch</p><ul>
        <li>Trader Joe's pre-made fajita chicken</li>
        <li>Almond flour tortilla</li>
        <li>Trader Joe's mixed cabbage greens</li>
        <li>Pico de gallo</li>
        <li>Hummus</li>
    </ul>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/bq9e6m8ycejfy7x6ls5c9/taco.jpg?rlkey=yhdvqj5lmqqov818v655f17i4&st=y58ufoal&raw=1",
        tags: ["eat_well"]
    },
    {
        id: '06-20-24',
        date: '06-20-24',
        title: "Relationship inventory",
        excerpt: `<p>I’ve been thinking about how everything is a relationship. We have relationships not only with other people, but with our</p>
        <ul>
        <li>body</li>
        <li>food</li>
        <li>business/work</li>
        <li>money</li>
        <li>exercise</li>
        <li>goals and dreams</li>
        <li>self</li>
        </ul>
        <p>It's interesting to observe the ways you show up in each as if it's a romantic relationship. What patterns do you notice?</p>
        <p>Would Body say it feels heard and respected, or that you do whatever you want without listening to it?</p>
        <p>Would Money say you're attentive and responsible, or avoidant and impulsive?</p>
        <p>Would Work say you're passionate and committed, or going through the motions?</p>
        <p>Would Food say you're mindful and appreciative, or rushed and distracted?</p>
        <p>Would Dreams say you believe in and support them, or that you ignore their calls?</p>
        <p>Would Self say that you're kind and gentle with her, or that you are critical and compare her to others?</p>
        <p>Everything's connected.</p>`,
        content: `<p>I’ve been thinking about how everything is a relationship. We have relationships not only with other people, but with our</p>
        <ul>
        <li>body</li>
        <li>food</li>
        <li>business/work</li>
        <li>money</li>
        <li>exercise</li>
        <li>goals and dreams</li>
        <li>self</li>
        </ul>
        <p>It's interesting to observe the ways you show up in each as if it's a romantic relationship. What patterns do you notice?</p>
        <p>Would Body say it feels heard and respected, or that you do whatever you want without listening to it?</p>
        <p>Would Money say you're attentive and responsible, or avoidant and impulsive?</p>
        <p>Would Work say you're passionate and committed, or going through the motions?</p>
        <p>Would Food say you're mindful and appreciative, or rushed and distracted?</p>
        <p>Would Dreams say you believe in and support them, or that you ignore their calls?</p>
        <p>Would Self say that you're kind and gentle with her, or that you are critical and compare her to others?</p>
        <p>Everything's connected.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/wmlvis6s3ry4suv25aa2h/home.jpg?rlkey=2q1jlmpdpntpc14xe6x10um73&st=wijl4csv&raw=1",
        tags: ["relationships"]
    },
    {
        id: '06-19-24',
        date: '06-19-24',
        title: "Two questions",
        excerpt: `<p>To take a relationship from good to great, according to Dr. Ana Yudini, ask these two questions:</p>
        <p><span className="bold">1. How can I make my response more active than passive?</span></p>
        <p><span className="bold">2. How can I make my response more constructive than destructive?</span></p>
        <p>You have probably been on the receiving end of passive listening, and in my experience it can make even some of the most well-meaning people come across as what you're saying doesn't matter.</p>
        <p>Tips from Ana on how to actively listen:</p>
        <ul>
            <li>Use body language</li>
            <li>Verbalize affirmations</li>
            <li>Be actively verbally involved</li>
            <li>Validate and support</li>
            <li>Offering support in problem-solving</li>
        </ul>
        <p>Tips on how to constructively respond:</p>
        <ul>
            <li>Take a strengths-based approach: think about their strengths and what they bring to the table, rather than what they're doing wrong</li>
            <li>Help improve their situation in some way, whether it's supporting or problem-solving</li>
            <li>Rather than being critical or contemptuous, you're responding in a way that is strengthening them and your relationship with them</li>
            <li>You're building them up rather than making them feel worse about themselves or about what they're sharing</li>
        </ul>
        <p>Sometimes sharing the truth can bring up painful emotions, but you can listen to your gut to know if the person is sharing it with your well-being in mind and if it's ultimately helpful in the long-term.</p>
        <p>Trust the vibes. Walk away if you're getting more passive than active, and more destructive than constructive.</p><p>Build up the people you care about, and spend time with people who build you up too. Everyone's going through something. A few words, or lack thereof, can make a big impact.</p>`,
        content: `<p>To take a relationship from good to great, according to Dr. Ana Yudini, ask these two questions:</p>
        <p><span className="bold">1. How can I make my response more active than passive?</span></p>
        <p><span className="bold">2. How can I make my response more constructive than destructive?</span></p>
        <p>You have probably been on the receiving end of passive listening, and in my experience it can make even some of the most well-meaning people come across as what you're saying doesn't matter.</p>
        <p>Tips from Ana on how to actively listen:</p>
        <ul>
            <li>Use body language</li>
            <li>Verbalize affirmations</li>
            <li>Be actively verbally involved</li>
            <li>Validate and support</li>
            <li>Offering support in problem-solving</li>
        </ul>
        <p>Tips on how to constructively respond:</p>
        <ul>
            <li>Take a strengths-based approach: think about their strengths and what they bring to the table, rather than what they're doing wrong</li>
            <li>Help improve their situation in some way, whether it's supporting or problem-solving</li>
            <li>Rather than being critical or contemptuous, you're responding in a way that is strengthening them and your relationship with them</li>
            <li>You're building them up rather than making them feel worse about themselves or about what they're sharing</li>
        </ul>
        <p>Sometimes sharing the truth can bring up painful emotions, but you can listen to your gut to know if the person is sharing it with your well-being in mind and if it's ultimately helpful in the long-term.</p>
        <p>Trust the vibes. Walk away if you're getting more passive than active, and more destructive than constructive.<p>Build up the people you care about, and spend time with people who build you up too. Everyone's going through something. A few words, or lack thereof, can make a big impact.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/d1wmat44yf0qwsqpzc2n6/bartusi.jpg?rlkey=urahezkyfby2rsc658q6ci7m4&st=b7c4o7wq&raw=1",
        tags: ["relationships"]
    },
    {
        id: '06-18-24',
        date: '06-18-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/55oolivwpeuogs1uzwtpy/eggyolk.jpg?rlkey=m2rc0xmn4tha9o2z0qtk29j79&st=gkmk030g&raw=1",
        tags: []
    },
    {
        id: '06-17-24',
        date: '06-17-24',
        title: "Interview with author Majo Molfino",
        excerpt: `<p>[intro]</p>
        <p className="question">Why do you write?</p>
        <p className="answer">I write because I enjoy getting into that zone of the imaginal, where anything is possible, where entire characters and encounters are born. I write to be delighted and surprised by the connections my psyche and soul are making. I write to understand myself more clearly.</p>
        <p className="question">What is a core philosophy that guides your creativity?</p>
        <p className="answer">I think a core philosophy is listening, as in turning down the noise of the world, in order to listen to what my subconscious is telling me. Listening to the mind, but also the body, where the body gets excited, is often where the writing begins to feel juicy. Being in a receptive state of listening allows more ideas to enter and flow.</p>
        <p className="answer">Somewhat paradoxically, I like to gather inspiration from a lot of other artists and art. I like to be in conversation with their works. I like to remix their tone or voice as a filter I layer onto mine, to create something completely different. At first, I used to believe this was a young, inexperienced part of myself, but with time I’ve realized I’m that sort of artist – where I need a lot of artistic inputs to help me feel inspired to create work. It’s
        hard for me to create from nothing. For example, I’ll ask, what would Emily Dickinson say about this strange encounter with this blue heron you had just now? I’ll take an element that is characteristic of her poetry, say brevity, and create a very brief poem about it. Some might call this imitation, but it’s different than that, it’s more like I’m filtering my poem through an element of her style or voice, to see how it clashes with mine to create something different altogether. Like mixing paint. I think most artists do some form of “stealing” like this.</p>
        <p className="question">Do you have any rituals or habits that help you get in flow?</p>
        <p className="answer">I sometimes do 10 minutes of free-writing before I begin to write-write, and that helps me get a bunch of junk out of my mind. I brain dump my worries and to-do’s and other random thoughts down, and often that helps create a clearing for the creative writing to begin.</p>
        <p className="question">How does creative resistance show up for you? How do you overcome it?</p>
        <p className="answer">I get “shiny object syndrome” when I’m about to finish a work that I’ve been working on for some time. The last ten percent of finishing a work is when I encounter a lot of resistance. Labeling it for what it is, and then telling myself, “Nice try,” helps. If I feel the urge to jump ship to a new project, I set the new project down and aside, and tell myself it’s going to be there when I finish my original goal. It then becomes a sort of reward I give myself for finishing the original work.</p>
        <img src="https://www.dropbox.com/scl/fi/d7df4arw65mow3pm3fhun/goodgirlmyth.jpeg?rlkey=plqn2jz242csv6in2aek9t6em&st=x1ketp6c&raw=1" className="blog-post-inner-image" />
        <p className="question">Your book <a href="https://www.amazon.com/BREAK-GOOD-GIRL-MYTH-Molfino/dp/0062894064/ref=sr_1_1?crid=1TMD6PFS2VQE0&dib=eyJ2IjoiMSJ9.3TKP3ElIqzzSNbUjU_inkBozD15r0JvCNjzCxQsJIC4X_5dYSRfyD9g7DtyRooUFxZZmNrwwtZc3fpycrSNFwYUV59xNjWVcidEbNG326w-UGnbk85HyYRXGWJ8GtWv_B_kw4rorPH6b_2izIslf7IF_MmjLvFDQUFLUEOSULX-_NWQVrOCk_c_VPkaF-TratYrYNQ-a7UfiCqrQa22qzBWeTZKiWfqE7RnNDgP0gkE.L1sZYWpX2Nm7LPYbJcKbo0hfNepNdbqSTtbQDGw98l0&dib_tag=se&keywords=break+the+good+girl+myth+book&qid=1718824868&sprefix=break+the+good+%2Caps%2C84&sr=8-1">Break the Good Girl Myth: How to Dismantle Outdated Rules, Unleash Your Power, and Design a More Purposeful Life</a> lists five myths holding back the “good girl”: rules, perfection, logic, harmony, and sacrifice. Is there a particular one that has been key for you to dismantle in the development of your creative voice and vision?</p>
        <p className="answer">I think dismantling the good girl myth of logic has been key for me to letting my characters lead, and allowing for more spontaneous moments of delight and surprise in the writing. I also think choosing story ideas that on the surface may feel “random” and “unreasonable” but are actually quite connected intuitively to themes I’m thinking about or grappling with. I think setting aside logic in order to trust my intuition has been so important, and obviously something I’m still working on.</p>
        <p className="question">How do you plan and organize your work for a project?</p>
        <p className="answer">I love to outline. I also really believe in accountability, so I either set it up so I have beta readers or a coach I turn pages into. And I like to use another work, or a few works from other authors as reference points and inspiration (as shared about creative inputs earlier). Basically, I’m someone who has an idea or vision, and then creates the structure for it, before allowing for moments of deviation and surprise. A part of me wishes I would just let go of the structure, but another part has just decided to embrace this tendency I have, as it seems to help me create enough momentum to make it to the finish line. I’m still trying to find the balance between more structured and chaotic energies in the creative process. It sure is a dance!</p>
        <p className="question">Has your approach changed between your first book and your current one?</p>
        <p className="answer">Not really. I honestly wish it had changed more. With the first book (non-fiction), I really focused on getting a framework down that made sense, and filling things in. With this fiction book, I find I’m doing something similar with a plot outline. The main difference is that when I sit down to write fiction, I’m engaging in more of a subconscious imaginal space that allows for more serendipity and spontaneity to arise. It’s less about making an argument, and more about seeing what happens when certain elements are brought together in space and time to interact.</p>
        <img src="https://www.dropbox.com/scl/fi/wuglqo6s7x50uuj766bjn/majo.jpeg?rlkey=9sux4ffsjfcqxmjkhvbdp4iaw&st=tkp4skce&raw=1" className="blog-post-inner-image" />
        <p className="question">What role does feedback play in your creative process?</p>
        <p className="answer">Because I studied design thinking, I really believe feedback is a gift. And I love the idea that a work is a collaboration between artist and recipient from the very beginning. A conversation. At the same time, I also trust the artist needs to go away and figure things
        out on their own for a period of time, before they can start the conversation. So like what I mentioned with structured and chaotic energies in art, the same can be said about privacy and sharing – it’s highly personal for every artist, to discover what makes the most sense for them, and to have the discrimination of mind to know when it makes sense to conceal vs. share.</p>
        <p className="question">How do you handle self-doubt or criticism about your work?</p>
        <p className="answer">Like most people, not very well! Self-doubt is debilitating, and it can really derail the whole path. Self-doubt and second-guessing one’s ability and capacity is the stuff that kills creative dreams.</p>
        <p className="answer">Criticism is interesting. I like to think feedback tells you about the person giving you feedback. Receiving criticism for something you’ve put out there can be beneficial in that you are putting yourself on a map, and people are giving a damn to write a review about what you have written. To reference Brené Brown, at least you’re “in the arena,” and not on the sidelines like most people, remaining spectators and critics their whole lives, versus actual creators.</p>
        <p className="question">How has becoming a mother shaped your creative voice?</p>
        <p className="answer">Becoming a mother has put things into perspective for me. I know where my priorities are. I mother my child, in the same way I mother my ideas and art. I have more patience, more kindness, and more of a long-view with my creative work now that I’ve become a mother. It’s less about getting things done in a certain time frame and on a specific deadline, and more about enjoying the process, being here for the process. There really is nowhere to go.</p>`,
        content: `<p>[intro]</p>
        <p className="question">Why do you write?</p>
        <p className="answer">I write because I enjoy getting into that zone of the imaginal, where anything is possible, where entire characters and encounters are born. I write to be delighted and surprised by the connections my psyche and soul are making. I write to understand myself more clearly.</p>
        <p className="question">What is a core philosophy that guides your creativity?</p>
        <p className="answer">I think a core philosophy is listening, as in turning down the noise of the world, in order to listen to what my subconscious is telling me. Listening to the mind, but also the body, where the body gets excited, is often where the writing begins to feel juicy. Being in a receptive state of listening allows more ideas to enter and flow.</p>
        <p className="answer">Somewhat paradoxically, I like to gather inspiration from a lot of other artists and art. I like to be in conversation with their works. I like to remix their tone or voice as a filter I layer onto mine, to create something completely different. At first, I used to believe this was a young, inexperienced part of myself, but with time I’ve realized I’m that sort of artist – where I need a lot of artistic inputs to help me feel inspired to create work. It’s
        hard for me to create from nothing. For example, I’ll ask, what would Emily Dickinson say about this strange encounter with this blue heron you had just now? I’ll take an element that is characteristic of her poetry, say brevity, and create a very brief poem about it. Some might call this imitation, but it’s different than that, it’s more like I’m filtering my poem through an element of her style or voice, to see how it clashes with mine to create something different altogether. Like mixing paint. I think most artists do some form of “stealing” like this.</p>
        <p className="question">Do you have any rituals or habits that help you get in flow?</p>
        <p className="answer">I sometimes do 10 minutes of free-writing before I begin to write-write, and that helps me get a bunch of junk out of my mind. I brain dump my worries and to-do’s and other random thoughts down, and often that helps create a clearing for the creative writing to begin.</p>
        <p className="question">How does creative resistance show up for you? How do you overcome it?</p>
        <p className="answer">I get “shiny object syndrome” when I’m about to finish a work that I’ve been working on for some time. The last ten percent of finishing a work is when I encounter a lot of resistance. Labeling it for what it is, and then telling myself, “Nice try,” helps. If I feel the urge to jump ship to a new project, I set the new project down and aside, and tell myself it’s going to be there when I finish my original goal. It then becomes a sort of reward I give myself for finishing the original work.</p>
        <img src="https://www.dropbox.com/scl/fi/d7df4arw65mow3pm3fhun/goodgirlmyth.jpeg?rlkey=plqn2jz242csv6in2aek9t6em&st=x1ketp6c&raw=1" className="blog-post-inner-image" />
        <p className="question">Your book <a href="https://www.amazon.com/BREAK-GOOD-GIRL-MYTH-Molfino/dp/0062894064/ref=sr_1_1?crid=1TMD6PFS2VQE0&dib=eyJ2IjoiMSJ9.3TKP3ElIqzzSNbUjU_inkBozD15r0JvCNjzCxQsJIC4X_5dYSRfyD9g7DtyRooUFxZZmNrwwtZc3fpycrSNFwYUV59xNjWVcidEbNG326w-UGnbk85HyYRXGWJ8GtWv_B_kw4rorPH6b_2izIslf7IF_MmjLvFDQUFLUEOSULX-_NWQVrOCk_c_VPkaF-TratYrYNQ-a7UfiCqrQa22qzBWeTZKiWfqE7RnNDgP0gkE.L1sZYWpX2Nm7LPYbJcKbo0hfNepNdbqSTtbQDGw98l0&dib_tag=se&keywords=break+the+good+girl+myth+book&qid=1718824868&sprefix=break+the+good+%2Caps%2C84&sr=8-1">Break the Good Girl Myth: How to Dismantle Outdated Rules, Unleash Your Power, and Design a More Purposeful Life</a> lists five myths holding back the “good girl”: rules, perfection, logic, harmony, and sacrifice. Is there a particular one that has been key for you to dismantle in the development of your creative voice and vision?</p>
        <p className="answer">I think dismantling the good girl myth of logic has been key for me to letting my characters lead, and allowing for more spontaneous moments of delight and surprise in the writing. I also think choosing story ideas that on the surface may feel “random” and “unreasonable” but are actually quite connected intuitively to themes I’m thinking about or grappling with. I think setting aside logic in order to trust my intuition has been so important, and obviously something I’m still working on.</p>
        <p className="question">How do you plan and organize your work for a project?</p>
        <p className="answer">I love to outline. I also really believe in accountability, so I either set it up so I have beta readers or a coach I turn pages into. And I like to use another work, or a few works from other authors as reference points and inspiration (as shared about creative inputs earlier). Basically, I’m someone who has an idea or vision, and then creates the structure for it, before allowing for moments of deviation and surprise. A part of me wishes I would just let go of the structure, but another part has just decided to embrace this tendency I have, as it seems to help me create enough momentum to make it to the finish line. I’m still trying to find the balance between more structured and chaotic energies in the creative process. It sure is a dance!</p>
        <p className="question">Has your approach changed between your first book and your current one?</p>
        <p className="answer">Not really. I honestly wish it had changed more. With the first book (non-fiction), I really focused on getting a framework down that made sense, and filling things in. With this fiction book, I find I’m doing something similar with a plot outline. The main difference is that when I sit down to write fiction, I’m engaging in more of a subconscious imaginal space that allows for more serendipity and spontaneity to arise. It’s less about making an argument, and more about seeing what happens when certain elements are brought together in space and time to interact.</p>
        <img src="https://www.dropbox.com/scl/fi/wuglqo6s7x50uuj766bjn/majo.jpeg?rlkey=9sux4ffsjfcqxmjkhvbdp4iaw&st=tkp4skce&raw=1" className="blog-post-inner-image" />
        <p className="question">What role does feedback play in your creative process?</p>
        <p className="answer">Because I studied design thinking, I really believe feedback is a gift. And I love the idea that a work is a collaboration between artist and recipient from the very beginning. A conversation. At the same time, I also trust the artist needs to go away and figure things
        out on their own for a period of time, before they can start the conversation. So like what I mentioned with structured and chaotic energies in art, the same can be said about privacy and sharing – it’s highly personal for every artist, to discover what makes the most sense for them, and to have the discrimination of mind to know when it makes sense to conceal vs. share.</p>
        <p className="question">How do you handle self-doubt or criticism about your work?</p>
        <p className="answer">Like most people, not very well! Self-doubt is debilitating, and it can really derail the whole path. Self-doubt and second-guessing one’s ability and capacity is the stuff that kills creative dreams.</p>
        <p className="answer">Criticism is interesting. I like to think feedback tells you about the person giving you feedback. Receiving criticism for something you’ve put out there can be beneficial in that you are putting yourself on a map, and people are giving a damn to write a review about what you have written. To reference Brené Brown, at least you’re “in the arena,” and not on the sidelines like most people, remaining spectators and critics their whole lives, versus actual creators.</p>
        <p className="question">How has becoming a mother shaped your creative voice?</p>
        <p className="answer">Becoming a mother has put things into perspective for me. I know where my priorities are. I mother my child, in the same way I mother my ideas and art. I have more patience, more kindness, and more of a long-view with my creative work now that I’ve become a mother. It’s less about getting things done in a certain time frame and on a specific deadline, and more about enjoying the process, being here for the process. There really is nowhere to go.</p><i className="fas fa-tree blog-post-ending-icon"></i>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/y73osyj8d30g8u7ztp0vw/majo4.jpeg?rlkey=8kt3fj8oibbqo3ih13cuaa3st&st=57m35hv2&raw=1",
        tags: []
    },
    {
        id: '06-13-24',
        date: '06-13-24',
        title: "Things we have in common",
        excerpt: `<p>I recently heard a talk given by the founder of Points for Patriots, a group whose mission is to bridge the military-civilian divide.</p><p>They begin their events with the question:</p><p>What's your favorite ice-cream flavor?</p><p>It's a simple starting point to feel connected again.</p><p>It feels good to focus on all the things we have in common, rather than all the ways we feel separate.</p>`,
        content: `<p>I recently heard a talk given by the founder of Points for Patriots, a group whose mission is to bridge the military-civilian divide.</p><p>They begin their events with the question:</p><p>What's your favorite ice-cream flavor?</p><p>It's a simple starting point to feel connected again.</p><p>It feels good to focus on all the things we have in common, rather than all the ways we feel separate.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/4j0u4rdciz1lxypu1nqm4/subwaypassing.jpg?rlkey=jmhz8qe8byyrec1lqg8vehiz9&st=5vszhq1g&raw=1",
        tags: ["nyc", "what_matters"]
    },
    {
        id: '06-12-24',
        date: '06-12-24',
        title: "Sex and the City",
        excerpt: `<p>Living in New York in my mid-30s writing my blog.</p><p>Watching Sex and the City for the first time ever.</p><p>I'm a little late, but right on time.</p><p>Yes Carrie does cross the line "from pleasantly neurotic to annoyingly troubled" (her words), and I love her.</p><p>The final line of the series:</p><p className="quote">…but the most exciting, challenging, and significant relationship of all is the one you have with yourself. And if you find someone to love the you you love - well, that’s just fabulous.</p>`,
        content: `<p>Living in New York in my mid-30s writing my blog.</p><p>Watching Sex and the City for the first time ever.</p><p>I'm a little late, but right on time.</p><p>Yes Carrie does cross the line "from pleasantly neurotic to annoyingly troubled" (her words), and I love her.</p><p>The final line of the series:</p><p className="quote">…but the most exciting, challenging, and significant relationship of all is the one you have with yourself. And if you find someone to love the you you love - well, that’s just fabulous.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/650q0zvkijco1igx3ak6s/carrie.jpg?rlkey=7y3kg267u1gjqma3pd4hpunea&st=cl3wsbwi&raw=1",
        tags: ["nyc"]
    },
    {
        id: '06-11-24',
        date: '06-11-24',
        title: "Color outside the lines",
        excerpt: `<p>Color outside the lines 🌹</p>`,
        content: `<p>Color outside the lines 🌹</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/njqncmqjt3w9n23a6upsm/IMG_1494.jpg?rlkey=w7evbs18lam210hcymno6c7zf&st=ami9z8si&raw=1",
        tags: ["listen_to_yourself", "nyc"]
    },
    {
        id: '06-9-24',
        date: '06-9-24',
        title: "Sweets",
        excerpt: `<p>It feels a little more like home here: I found a cute dessert place with vegan and gluten-free options.</p><p><a href="https://g.co/kgs/x1Wv9Tp">Little Cupcake Bakeshop</a> in Nolita has the most delicious chocolate cupcakes and chocolate chip cookies.</p>`,
        content: `<p>It feels a little more like home here: I found a cute dessert place with vegan and gluten-free options!</p><p><a href="https://g.co/kgs/x1Wv9Tp">Little Cupcake Bakeshop</a> in Nolita has the most delicious chocolate cupcakes and chocolate chip cookies.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/ku9ezrwmyurigdexhjotx/cupcake.jpg?rlkey=h8kur3ezb7hdlu4c0qfzjxetz&st=9titffby&raw=1",
        tags: ["eat_well", "nyc"]
    },
    {
        id: '06-8-24',
        date: '06-8-24',
        title: "Make up your own steps",
        excerpt: `<p className="quote">I realized that simply being a dancer, taking directions from others, couldn’t fulfill me.</p><p className="quote">If I wanted to dance, I would have to make the dances myself.</p><p className="quote">...What I did know was that when I made up my own steps and when I hit the movement that felt right, BINGO!<br /><br />–Twyla Tharp</p>`,
        content: `<p className="quote">I realized that simply being a dancer, taking directions from others, couldn’t fulfill me.</p><p className="quote">If I wanted to dance, I would have to make the dances myself.</p><p className="quote">...What I did know was that when I made up my own steps and when I hit the movement that felt right, BINGO!<br /><br />–Twyla Tharp</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/fl18tdmxzvleznf0o24m3/twyla.jpg?rlkey=i7y0wa495mnvyvjl6mx0jebba&st=84osorzd&raw=1",
        tags: ["books", "keep_it_moving", "words_to_live_by"]
    },
    {
        id: '06-7-24',
        date: '06-7-24',
        title: "Fragrance routine",
        excerpt: `<p>Some favorites in my current scent routine:</p><p>Cool morning shower for that just-showered skin smell (and to wash off any bad dream vibes)</p><p>Arm & Hammer toothpaste (all-time favorite)</p><p>TheraBreath oral rinse (alcohol-free, so it doesn't kill the good bacteria and mess with your skin or gut)</p><p>Saltair Santal Bloom lotion</p><p>Replica Springtime in the Park perfume (top notes: pear, bergamot, blackcurrant. middle notes: lily of the valley, jasmine, rose mascena. base notes: musk, vanilla, ambrox...like a fresh spring smell with the weight and edge of musk)</p><p>I also like having essential oils on hand to breathe in for a calming effect or a pickmeup - my go-to is rosemary</p>`,
        content: `<p>Some favorites in my current scent routine:</p><p>Cool morning shower for that just-showered skin smell (and to wash off any bad dream vibes)</p><p>Arm & Hammer toothpaste (all-time favorite)</p><p>TheraBreath oral rinse (alcohol-free, so it doesn't kill the good bacteria and mess with your skin or gut)</p><p>Saltair Santal Bloom lotion</p><p>Replica Springtime in the Park perfume (top notes: pear, bergamot, blackcurrant. middle notes: lily of the valley, jasmine, rose mascena. base notes: musk, vanilla, ambrox...like a fresh spring smell with the weight and edge of musk)</p><p>I also like having essential oils on hand to breathe in for a calming effect or a pickmeup - my go-to is rosemary</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/8zsw52wd6cxirq4qn144z/IMG_1477.jpg?rlkey=0ftk4b2bz241lrtfnq17lkp4j&st=df368x0s&raw=1",
        tags: ["beauty"]
    },
    {
        id: '06-6-24',
        date: '06-6-24',
        title: "Relax",
        excerpt: `<p className="quote">The more relaxed you are, the better you are at everything: the better you are with your loved ones, the better you are with your enemies, the better you are at your job, the better you are with yourself.<br/>–Bill Murray</p>`,
        content: `<p className="quote">The more relaxed you are, the better you are at everything: the better you are with your loved ones, the better you are with your enemies, the better you are at your job, the better you are with yourself.<br/>–Bill Murray</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/4c4kpa4gr4rim698im4r4/pinkflower.jpg?rlkey=xo5bxsh15g5owu1himtl5wf0a&st=4dgiqky7&raw=1",
        tags: ["words_to_live_by"]
    },
    {
        id: '06-5-24',
        date: '06-5-24',
        excerpt: `<p>One day I will have a cat. Until then, I adore that my apartment building has resident turtles and a cat!</p>`,
        content: `<p>One day I will have a cat. Until then, I adore that my apartment building has resident turtles and a cat!</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/14746ya9nk40i3s5snkum/turtles.jpg?rlkey=0x4t31b129hz37si80rdpefig&st=a3p6e9d9&raw=1",
        imageUrl2: "https://www.dropbox.com/scl/fi/92tnr6kkdfrmdap9q71rf/cat.jpg?rlkey=9e87uml7sk48kmon1yzwpq73o&st=yzkxfjqk&raw=1",
        tags: []
    },
    {
        id: '06-3-24',
        date: '06-3-24',
        title: "Made with love",
        excerpt: `<p>Waited in line an hour for Dosas NYC in Washington Square Park. Crepes made of rice and lentil and the best samosas I've ever had. Made and served with love. Recommend.</p>`,
        content: `<p>Waited in line an hour for Dosas NYC in Washington Square Park. Crepes made of rice and lentil and the best samosas I've ever had. Made and served with love. Recommend.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/x0u91f3kweb7qy4wq3wus/dosas.jpg?rlkey=lnoit0g7jb1m8ynu1t934mhan&st=eeodt88d&raw=1",
        tags: ["eat_well", "nyc"]
    },
    {
        id: '06-2-24',
        date: '06-2-24',
        title: "Lunch",
        excerpt: `<p>Something about throwing everything into a skillet on the stove makes it feel like a "real" meal while also being easy enough for my kitchen laziness.</p><p>Chicken salad:</p><ul><li>Chicken thighs (baked prior in the oven 40 min at 425 - easy because it's hands-off cooking. A rotisserie chicken would also be easy.)</li><li>Cruciferous crunch mix from Trader Joe's</li><li>Steamed lentils from Trader Joe's</li><li>Pumpkin seeds</li><li>Pink crystal salt</li></ul>`,
        content: `<p>Something about throwing everything into a skillet on the stove makes it feel like a "real" meal while also being easy enough for my kitchen laziness.</p><p>Chicken salad:</p><ul><li>Chicken thighs (baked prior in the oven 40 min at 425 - easy because it's hands-off cooking. A rotisserie chicken would also be easy.)</li><li>Cruciferous crunch mix from Trader Joe's</li><li>Steamed lentils from Trader Joe's</li><li>Pumpkin seeds</li><li>Pink crystal salt</li></ul>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/5githwayqs4hyj1b7fyba/salad.jpg?rlkey=svxwghbji5ruozmu3lx1be6du&st=vtt5imph&raw=1",
        tags: ["eat_well", "listen_to_yourself"]
    },
    {
        id: '06-1-24',
        date: '06-1-24',
        title: "Effort less",
        excerpt: `<p>The ultimate power move is to be relaxed.</p>
        <p>In stillness the quiet voice of my intuition can be heard. And in that space, awareness often comes through, which helps dissolve fear, in turn dissolving blocks and allowing me to naturally move to the things I want.</p>
        <p>Effort less.</p>`,
        content: `<p>The ultimate power move is to be relaxed.</p>
        <p>In stillness the quiet voice of my intuition can be heard. And in that space, awareness often comes through, which helps dissolve fear, in turn dissolving blocks and allowing me to naturally move to the things I want.</p>
        <p>Effort less.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/w0hr30ry94vzur8g37qpp/wildflowers.jpg?rlkey=a9wx9x08zmrhvytynaz2yk1vj&st=0xa28nhf&raw=1",
        tags: ["listen_to_yourself"]
    },
    {
        id: '05-31-24',
        date: '05-31-24',
        title: "",
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/cuzgkyubrftt1mrcete0a/books.jpg?rlkey=4l5lhxxh80695hplf9ty9g045&st=yjt4qib7&raw=1",
        tags: []
    },
    {
        id: '05-20-24',
        date: '05-20-24',
        excerpt: ``,
        content: ``,
        imageUrl1: "https://www.dropbox.com/scl/fi/ds9pie7azx03m75046355/centralpark.jpg?rlkey=iknggsa7q2rytczgfs1gt98cx&st=9hnnvuol&raw=1",
        tags: ["nyc"]
    },
    {
        id: '05-19-24',
        date: '05-19-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/hq5fp6vfjqzt2fg1vpvwh/subwaystairs.jpg?rlkey=2atq2051ynie677qupuyqfe9v&st=dk96sr1f&raw=1",
        tags: []
    },
    {
        id: '05-17-24',
        date: '05-17-24',
        title: "The Whitney Museum",
        excerpt: `<p>Free Fridays at the Whitney Museum</p><p>Wanda Gág's work struck me most tonight. She captures the movement she sees in objects and nature, challenging the belief that anything in life is truly inanimate.</p>`,
        content: `<p>Free Fridays at the Whitney Museum</p><p>Wanda Gág's work struck me most tonight. She captures the movement she sees in objects and nature, challenging the belief that anything in life is truly inanimate.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/mmz5ydstcnwobcltjvmoj/IMG_1120.jpg?rlkey=3pxflozt7vfv7pr9n84l5h9ke&st=peuo7itv&raw=1",
        imageUrl2: "https://www.dropbox.com/scl/fi/rr5w3ebaavhwzt3nie3bl/IMG_1103.jpeg?rlkey=ajio29d99ytdhph8p5nkvwww9&st=6b1z33vf&raw=1",
        tags: ["keep_it_moving", "nyc"]
    },
    {
        id: '05-12-24',
        date: '05-12-24',
        title: "Breakfast",
        excerpt: `<p>I look forward to this breakfast every morning 😍</p><ul><li>Overnight oats with chia seeds</li><li>Pumpkin seed granola from Trader Joe's (for a little crunch)</li><li>Almond butter</li><li>Organic blueberries</li><li>Organic blackberries</li><li>Pumpkin seeds</li></ul><p>🖤 heart health: magnesium, healthy fats, and fiber.</p><p>🖤 brain health: omega-3s, antioxidants, and vitamin E.</p><p>🖤 digestive health: fiber, probiotics, and magnesium.</p><p>🖤 stay full and have steady energy for a few hours thanks to complex carbs and healthy fats.</p>`,
        content: `<p>I look forward to this breakfast every morning 😍</p><ul><li>Overnight oats with chia seeds</li><li>Pumpkin seed granola from Trader Joe's (for a little crunch)</li><li>Almond butter</li><li>Organic blueberries</li><li>Organic blackberries</li><li>Pumpkin seeds</li></ul><p>🖤 heart health: magnesium, healthy fats, and fiber.</p><p>🖤 brain health: omega-3s, antioxidants, and vitamin E.</p><p>🖤 digestive health: fiber, probiotics, and magnesium.</p><p>🖤 stay full and have steady energy for a few hours thanks to complex carbs and healthy fats.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/jndowwyfd8q00g4o5ozo2/breakfast.jpg?rlkey=5ducvgeahs306a0s4rfkfpatn&st=s1mmetgg&raw=1",
        tags: ["eat_well"]
    },
    {
        id: '05-11-24',
        date: '05-11-24',
        title: "Birdsong",
        excerpt: `<p>I read the other day that birdsong is calming to our nervous systems because if birds are singing, it means there is no imminent danger.</p><p>Self-expression is an act of courage to declare your choices.</p><p>Humans are a social species, where belonging is closely linked to survival.</p><p>When you've had experiences with people that make you lose trust in others/yourself, expressing yourself can feel threatening.</p><p>Tend to the parts of yourself that have relied on others for safety, validation, or direction. Be courageous as you express yourself creatively.</p>`,
        content: `<p>I read the other day that birdsong is calming to our nervous systems because if birds are singing, it means there is no imminent danger.</p><p>I think in a similar way, if we don't feel safe, it is hard to express ourselves.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/upv020hele7rf7egpt17e/cherryblossoms.jpg?rlkey=tu0lg73lmscjy59t76p54rph5&st=a0xvnwzg&raw=1",
        tags: ["make_things"]
    },
    {
        id: '05-10-24',
        date: '05-10-24',
        title: "Slow is smooth",
        excerpt: `<p>Slow is smooth and smooth is fast</p>`,
        content: `<p>Slow is smooth and smooth is fast</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/cvrghrivza9vx5fj6ufkv/IMG_0904.jpeg?rlkey=uw2bt8ktffr71kc97vqx1yikv&st=cchl09th&raw=1",
        tags: ["words_to_live_by"]
    },
    {
        id: '05-9-24',
        date: '05-9-24',
        title: "Rhythm",
        excerpt: `<p>Syncing up to nature's rhythm watching the trees sway</p>`,
        content: `<p>Syncing up to nature's rhythm watching the trees sway</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/ut8r8957meqfowu7dl1yc/sky.jpg?rlkey=hogznpdjmwxyhmhrb7lgyflfv&st=joncxr5s&raw=1",
        tags: []
    },
    {
        id: '05-8-24',
        date: '05-8-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/0vki8u9kl0w6gzfc5q1my/shoessubway.jpg?rlkey=5b4uehia0na0fpj1nwhdshk3q&st=i88dkejh&raw=1",
        tags: ["nyc"]
    },
    {
        id: '05-7-24',
        date: '05-7-24',
        title: "Season's beginning",
        excerpt: `<p>Springtime in New York is a vibe</p>`,
        content: `<p>Springtime in New York is a vibe</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/0dgszhy8o56m8y6p0h46s/IMG_0710_jpg-2.jpg?rlkey=6b7xyh7b3fjrsdahz7pocwjrw&st=gsbl2qkd&raw=1",
        tags: ["nyc"]
    },
    {
        id: '05-6-24',
        date: '05-6-24',
        title: "Recipe for feeling good",
        excerpt: `<ul><li>Protein</li><li>Healthy fats</li><li>Colorful fruits and veggies</li><li>Respecting your hunger cues</li><li>Gratitude for a nourishing meal</p></ul>`,
        content: `<ul><li>Protein</li><li>Healthy fats</li><li>Colorful fruits and veggies</li><li>Respecting your hunger cues</li><li>Gratitude for a nourishing meal</p></ul>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/chgsn548bz8f1kq08uaqw/bowl.jpg?rlkey=1nj6xwa8zxc6psvz27ljy82ay&st=71fzc8i5&raw=1",
        tags: ["eat_well", "listen_to_yourself"]
    },
    {
        id: '05-5-24',
        date: '05-5-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/w7menwpyx9ggpjs6p72gt/hudson.jpg?rlkey=i1v1gjc5n7jel5bkivhl01szm&st=h4e27qqb&raw=1",
        tags: []
    },
    {
        id: '04-30-24',
        date: '04-30-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/sh080bs3757j29ul2wwm6/wildflowers.jpg?rlkey=mhowyfini14mxcbo8aqsv427g&st=lh8px5yp&raw=1",
        tags: []
    },
    {
        id: '04-29-24',
        date: '04-29-24',
        title: 'Nonviolent Communication',
        excerpt: `<p>One of those books that feels essential to read as a human being who interacts with other human beings.</p>
        <p>Oftentimes conflict happens because we don't know how to clearly express our needs, and we react to others without listening for the needs behind their words.</p>
        <p>It's a book on how to be a compassionate listener and communicator.</p>
        <p className="italics">When we listen for feelings and needs, we no longer see people as monsters.</p>
        <p className="italics">Time and again, people transcend the paralyzing effects of psychological pain when they have sufficient contact with someone who can hear them empathetically.</p>`,
        content: `<p>One of those books that feels essential to read as a human being who interacts with other human beings.</p>
        <p>Oftentimes conflict happens because we don't know how to clearly express our needs, and we react to others without listening for the needs behind their words.</p>
        <p>It's a book on how to be a compassionate listener and communicator.</p>
        <p className="italics">When we listen for feelings and needs, we no longer see people as monsters.</p>
        <p className="italics">Time and again, people transcend the paralyzing effects of psychological pain when they have sufficient contact with someone who can hear them empathetically.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/h7myc4er7mub9xvcsg3s5/nvc.jpg?rlkey=u73g8cje9p0xjmwgyi1i2b1jf&st=zyqjw6i3&raw=1",
        tags: ["books", "relationships"]
    },
    {
        id: '04-27-24',
        date: '04-27-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/ge3h3dll4cb4noaj91wqb/IMG_0588.jpg?rlkey=tna9ytw38hpl5myltdv4gt09u&st=7pse7ivy&raw=1",
        tags: ["nyc"]
    },
    {
        id: '04-26-24',
        date: '04-26-24',
        title: "Who lives in New York?",
        excerpt: `<p className="quote">...the city is uncomfortable and inconvenient; but New Yorkers temperamentally do not crave comfort and convenience–if they did they would live elsewhere.<br/>–E.B. White</p>
        <p>New York sharpens you. The way you speak, move, dress, how you spend money, your adaptability, how you deal with adversity, your point of view, what you're about. It demands truth and keeps you honest. It'll let you know if you're onto something or not.</p>
        <p>What New Yorkers value in people, according to Chat GPT:</p>
        <ul>
            <li>authenticity</li>
            <li>genuineness</li>
            <li>intellect</li>
            <li>curiosity</li>
            <li>sharpness</li>
            <li>wit</li>
            <li>being cultured</li>
            <li>diversity</li>
            <li>discernment</li>
            <li>sophistication</li>
            <li>confidence without arrogance</li>
            <li>resilience</li>
            <li>adaptability</li>
            <li>the ability to thrive under pressure</li>
            <li>originality</li>
            <li>fresh insights</li>
            <li>engagement</li>
        </ul>
        <p>Lots of people have lived in New York. I wonder, what kind of person <span className="italics">stays</span> in New York?</p>`,
        content: `<p className="quote">...the city is uncomfortable and inconvenient; but New Yorkers temperamentally do not crave comfort and convenience–if they did they would live elsewhere.<br/>–E.B. White</p>
        <p>New York sharpens you. The way you speak, move, dress, how you spend money, your adaptability, how you deal with adversity, your point of view, what you're about. It demands truth and keeps you honest. It'll let you know if you're onto something or not.</p>
        <p>What New Yorkers value in people, according to Chat GPT:</p>
        <ul>
            <li>authenticity</li>
            <li>genuineness</li>
            <li>intellect</li>
            <li>curiosity</li>
            <li>sharpness</li>
            <li>wit</li>
            <li>being cultured</li>
            <li>diversity</li>
            <li>discernment</li>
            <li>sophistication</li>
            <li>confidence without arrogance</li>
            <li>resilience</li>
            <li>adaptability</li>
            <li>the ability to thrive under pressure</li>
            <li>originality</li>
            <li>fresh insights</li>
            <li>engagement</li>
        </ul>
        <p>Lots of people have lived in New York. I wonder, what kind of person <span className="italics">stays</span> in New York?</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/y0xj1vgvp244ukpnifytb/timessquare.jpg?rlkey=cb8s3d588ihu8bbht01w3x541&st=5vrx4thk&raw=1",
        tags: ["nyc"]
    },
    {
        id: '04-25-24',
        date: '04-25-24',
        title: "On aging",
        excerpt: `<p>Since I was young, I’ve been struck by how limiting our society's ideas are about aging.</p><p>It's why I've always loved latebloomer stories. These people prove that "being old" is a matter of choice, not age.</p><p>A light grip. An open heart. An active imagination. Good health. These are youthful qualities I can continue cultivating over time.</p><p>Aging gracefully is the art of living.</p>`,
        content: `<p>Since I was young, I’ve been struck by how limiting our society's ideas are about aging.</p><p>It's why I've always loved latebloomer stories. These people prove that "being old" is a matter of choice, not age.</p><p>A light grip. An open heart. An active imagination. Good health. These are youthful qualities I can continue cultivating over time.</p><p>Aging gracefully is the art of living.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/4gn2vlkubby4aieijvluo/porkbelly.JPG?rlkey=tchytmozjbvpzbwxd4l24clt9&st=vzpxbmmc&raw=1",
        tags: ["eat_well", "seasons"]
    },
    {
        id: '04-22-24',
        date: '04-22-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/a8272424hbeic62rhpwuo/cocktails.jpg?rlkey=ckystsf6vu8kv004ry8smgmu0&st=x2a6vua3&raw=1",
        tags: []
      },
      {
        id: '04-21-24',
        date: '04-21-24',
        excerpt: ``,
        content: ``,
        imageUrl1: "https://www.dropbox.com/scl/fi/hze05vgtabcx0ythf9fw7/roses.jpg?rlkey=zcl59mblwb4hh47fk9p5o8vov&st=7wah6xl7&raw=1",
        tags: []
      },
      {
        id: '04-20-24',
        date: '04-20-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/q28a7ze8a0ni3b5qo3rvb/yiji.jpg?rlkey=pvwaf6n4c08ygs2o9hyaowd5v&st=zvnqm9hg&raw=1",
        tags: ["eat_well", "nyc"]
    },
    {
        id: '04-18-24',
        date: '04-18-24',
        title: "Now I can see the moon",
        excerpt: `<p className="quote">Barn's burnt down – now I can see the moon.</p><p>Haiku by Mizuta Masahide</p><p>I think a level of maturity (which I'm thinking could be defined as humble wisdom) happens when you go through something that you can only get through not with fighting and effort and winning, but with vulnerability, acceptance, and surrender.</p><p>There is an acceptance of humility involved in learning, but we're too attached to our identity or beliefs about the way things are/should be, we dig in further instead of allowing for something different, we get stuck in the fight. The only way out is through, and on the other side of the the fight is truth.</p><p>Before I'd gone through this process myself, it was like I could sense this knowing in other people but couldn't identify it.</p><p>When I had a personal experience of it, I could see the humble wisdom in others.</p>`,
        content: `<p className="quote">Barn's burnt down – now I can see the moon.</p><p>Haiku by Mizuta Masahide</p><p>I think a level of maturity (which I'm thinking could be defined as humble wisdom) happens when you go through something that you can only get through not with fighting and effort and winning, but with vulnerability, acceptance, and surrender.</p><p>There is an acceptance of humility involved in learning, but if it's too scary to accept those things, if our ego/beliefs/the way we believe things are or should be digs in further instead of allowing for something different, we get stuck in the fight. The only way out is through, and on the other side of the the fight is truth.</p><p>Before I'd gone through this process myself, it was like I could sense this knowing in other people but couldn't identify it.</p><p>When I had a personal experience of it, I could see the humble wisdom in others.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/75fskhje0tv9gsfmesnwd/mural.jpg?rlkey=ak6b2et1mebdkww90txi3eaje&st=0jxal14r&raw=1",
        tags: []
      },
      {
        id: '04-17-24',
        date: '04-17-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/g3267pfs5ergmqsjjwhzl/building.jpg?rlkey=jajxht6ufthni25d9acj7grc2&st=nalm4fry&raw=1",
        tags: ["nyc"]
      },
      {
        id: '04-16-24',
        date: '04-16-24',
        excerpt: `<p>Mexico on the mind</p>`,
        content: `<p>Mexico on the mind</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/qgvtl8h5a34q89o3ub7h0/sayulita.jpeg?rlkey=mgt6ddmsfv1fbqjmhnmzbsnik&st=st01olai&raw=1",
        tags: ["travels"]
    },
    {
        id: '03-21-24',
        date: '03-21-24',
        title: "It's not a numbers game",
        excerpt: `<p>True happiness comes from what cannot be measured or chased:</p><p>health</p><p>love</p><p>creativity</p><p>peace</p><p>imagination</p>`,
        content: `<p>True happiness comes from what cannot be measured or chased:</p><p>health</p><p>love</p><p>creativity</p><p>peace</p><p>imagination</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/50xtv6duaznh8zmsfhj4b/IMG_9406.jpg?rlkey=ungq0oqsbqzaww3im6cm4zwe2&st=v9yr5cww&raw=1",
        tags: ["what_matters"]
    },
    {
        id: '02-12-24',
        date: '02-12-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/e66fycshy29xufek9bh32/tree.jpg?rlkey=eul7okumjopc539625lbovds2&st=14ncf08t&raw=1",
        tags: []
    },
    {
        id: '01-21-24',
        date: '01-21-24',
        title: "Sunday morning",
        excerpt: ``,
        content: ``,
        imageUrl1: "https://www.dropbox.com/scl/fi/ahvuasfflupu67vakd82p/bed.jpg?rlkey=ta5uz9tqcxz1rw6jsirjys2ci&st=0q2xz3i9&raw=1",
        tags: []
    },
    {
        id: '01-13-24',
        date: '01-13-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/xods08mvphn47b0588n9y/subway.jpg?rlkey=4ok3fvel2yreqrmxfyn9anppk&st=t9221ybf&raw=1",
        tags: []
    },
    {
        id: '08-30-24',
        date: '08-30-24',
        title: "Music",
        excerpt: `<p>Listening to music walking New York City streets - vibes on vibes.</p>`,
        content: `<p>Listening to music walking New York City streets - vibes on vibes.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/tuhnaan3mboe24jatvpcd/williamsburg.jpg?rlkey=vfhioyz3z3nyhq3std71v100y&st=wm4cj0s1&raw=1",
        tags: []
    },
    {
        id: '08-1-24',
        date: '08-1-24',
        title: "No competition",
        excerpt: `<p>When you're playing the same game as everyone else, there will always be someone better.</p><p>Get off the beaten path and be in a category of one.</p>`,
        content: `<p>When you're playing the same game as everyone else, there will always be someone better.</p><p>Get off the beaten path and be in a category of one.</p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/9qxybffo53ilfb70lqcvt/trail.jpg?rlkey=l5umlvz0qzl98tmus2ybzvot4&st=dahbx9kw&raw=1",
        tags: ["listen_to_yourself"]
    },
    {
        id: '07-30-24',
        date: '07-30-24',
        excerpt: `<p></p>`,
        content: `<p></p>`,
        imageUrl1: "https://www.dropbox.com/scl/fi/n2cddupbq67u4lrwgntsb/k.jpg?rlkey=nb1ktgjb4qr9pe5tgynzbu1c9&st=ch5e9qt4&raw=1",
        tags: []
    },
  ];
  
  export default allPosts;
  