import React, { useEffect } from 'react';

const CoachingPage = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    
    // Append the script to the body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/kia-katherine/coaching?primary_color=f781f8"
      style={{ minWidth: '320px', height: '700px' }}
    ></div>
  );
};

export default CoachingPage;
