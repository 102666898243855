import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { auth } from '../../firebase'; // Import Firebase authentication
import { Button, Modal, TextInput } from '@mantine/core';

const Comments = ({ postId, isModal }) => {
  const [comment, setComment] = useState('');
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showNameEmail, setShowNameEmail] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  // Fetch user information when the component mounts
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setShowNameEmail(!currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    // Send email using EmailJS
    try {
        await emailjs.send('service_iu8s11a', 'template_oc9siao', {
          postId,
          name,
          email,
          comment,
          userId: user?.uid ?? "",
        }, 'Ubvuq6P8uPurCN1CM');
        setModalOpened(false);
        alert('Message sent!');
        setComment('');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to submit comment. Please try again later.');
      }
  };

  return (
    <>
      {isModal ? <i className="fas fa-envelope" onClick={() => setModalOpened(true)}></i> :
        <div className="post-message">
          <h3 className="reddit-mono-400">Leave a message</h3>
          <form onSubmit={handleCommentSubmit} className="flex flex-col">
            {showNameEmail && <><TextInput
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="reddit-mono-400"
              required
            />
            <TextInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="reddit-mono-400"
              required
            /></>}
            <TextInput
              type="text"
              placeholder="Message"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="reddit-mono-400"
              required
            />
            <button type="submit" className="reddit-mono-400">Send</button>
          </form>
        </div>
      }
      {modalOpened &&
        <Modal title="send a message" withCloseButton styles={{ title: { fontFamily: "Reddit Mono" }, close: { margin: 0} }} opened={modalOpened} onClose={() => setModalOpened(false)}>
          <form className="form reddit-mono-400" onSubmit={handleCommentSubmit}>
            {showNameEmail &&
              <>
                <TextInput
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="reddit-mono-400"
                  required
                />
                <TextInput
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="reddit-mono-400"
                  required
                />
              </>
            }
            <TextInput
              type="text"
              placeholder="Message"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="reddit-mono-400"
              required
            />
            <Button type="submit" variant="outline">Send</Button>
          </form>
        </Modal>}
    </>
  );
};

export default Comments;
