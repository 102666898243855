import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import SignOutButton from './SignOutButton';
import { Link } from 'react-router-dom';
import { Notification } from '@mantine/core';

const Header = ({ toggleModal }) => {
  const [user, setUser] = useState(null);
  const [showSignedOutNotification, setShowSignedOutNotification] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // const tags = ["beauty", "books", "eat_well", "keep_it_moving", "listen_to_yourself", "make_things", "nyc", "relationships", "travels", "what_matters", "words_to_live_by"];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = (signedOut) => {
    setShowSignedOutNotification(signedOut);
    setTimeout(() => {
      setShowSignedOutNotification(false);
    }, 2500);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="header flex items-center justify-between">
      <div className="flex items-center">
        <i className="fas fa-bars" onClick={toggleMenu}></i>
        <h1 className="ibm-plex-mono-bold">
          <Link to="/">season's beginning</Link>
        </h1>
      </div>
      {showSignedOutNotification && (
        <Notification
          className="notification"
          color="white"
          icon={false}
        >
          You have signed out successfully.
        </Notification>
      )}
      <div className={`sidebar reddit-mono-400 ${menuOpen ? 'open' : ''}`}>
        <button className="sidebar-close-button" onClick={closeMenu}>
          <i className="fas fa-times"></i>
        </button>
        <div className="menu">
          {/* {tags.map((tag) => {
            return <Link to={`/tag/${tag}`} onClick={() => closeMenu()}>#{tag}</Link>
          })} */}
          <Link to="/coaching" className="sign-in-link">
              book a coaching call
          </Link>
          {/* <Link onClick={toggleModal}>
            say hi
          </Link> */}
          {!user ? (
            <Link to="/sign-in" className="sign-in-link" onClick={toggleMenu}>
              sign in
            </Link>
          ) : <SignOutButton onSignOut={handleSignOut} />}
        </div>
      </div>
    </div>
  );
};

export default Header;
