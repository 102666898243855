import './App.css';
import { useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import BlogPage from './components/Blog/BlogPage';
import BlogPost from './components/Blog/BlogPost';
import BlogPostForm from './components/Blog/BlogPostForm';
import Homepage from './components/Homepage';
import Signup from './components/Signup';
import SignIn from './components/SignIn';
import TagPage from './components/Blog/TagPage';
import allPosts from './components/Blog/data/blogPosts';
import CoachingPage from './components/CoachingPage';

import { Modal } from '@mantine/core';
import useAnalytics from './hooks/useAnalytics';
import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';
import emailjs from 'emailjs-com';
import ContactForm from './components/ContactForm';
import { Notification } from '@mantine/core';

function App() {
  const [showSignedUpNotification, setShowSignedUpNotification] = useState(false);
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const navigate = useNavigate();
  useAnalytics(navigate);

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const handleSignup = (signedUp) => {
    setShowSignedUpNotification(signedUp);
    debugger;
    setTimeout(() => {
      setShowSignedUpNotification(false);
    }, 3000)
  };

  const handleSendMessage = (messageSent) => {
    setShowMessageSent(messageSent);
    setTimeout(() => {
      setShowMessageSent(false);
    }, 3000)
  };

  return (
    <MantineProvider>
      <div className="App">
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/blog" element={<BlogPage toggleModal={toggleModal} />} posts={allPosts} />
            <Route path="/blog/:postId" element={<BlogPost />} />
            <Route path="/new-post" element={<BlogPostForm />} />
            <Route path="/tag/:tagName" element={<TagPage />} />
            <Route path="/coaching" element={<CoachingPage />} />
        </Routes>

        <Modal opened={modalOpened} onClose={() => setModalOpened(false)} withCloseButton={false}>
          <ContactForm onClose={() => setModalOpened(false)} handleSendMessage={handleSendMessage} />
        </Modal>

        {showSignedUpNotification && <Notification
            className="notification"
            show={showSignedUpNotification ? "true" : "false"}
            color="white"
            icon={false}
        >
            Your account was created. Welcome!
        </Notification>}
        {showMessageSent && <Notification
            className="notification"
            show={showMessageSent ? "true" : "false"}
            color="white"
            icon={false}
        >
            Message sent
        </Notification>}
      </div>
    </MantineProvider>
  );
}

export default App;
