import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import allPosts from './data/blogPosts';
import parse from 'html-react-parser';
import Likes from './Likes';
import Comments from './Comments';
import LazyLoad from 'react-lazyload';

const BlogList = () => {
    const [loadedImages, setLoadedImages] = useState({});
    const [showGridView, setShowGridView] = useState(true);
    const [transitioning, setTransitioning] = useState(false);

    const handleLoad = (id) => {
        setLoadedImages((prev) => ({ ...prev, [id]: true }));
    };

    const toggleView = () => {
        setTransitioning(true);
        setTimeout(() => {
            setShowGridView(!showGridView);
            setTransitioning(false);
        }, 450); // Match this duration with CSS transition duration
    };

  return (
        <>
            <div className={`${showGridView ? "page-container-grid-view" : "page-container"}`}>
                {/* <div className="view-buttons">
                    <button onClick={() => toggleView()}>{showGridView ? <i class="fa-solid fa-list"></i> : <i className="fas fa-table-cells-large"></i>}</button>
                </div> */}
                <div className={`${transitioning ? 'fade-out' : ''} ${showGridView ? "blog-container-grid-view" : "blog-container blog-container-list-view"}`}>
                    {Object.values(allPosts).map((post) => (
                        <div key={post.id} id={post.id} className={`post ${post.imageUrl2 ? 'span-two' : ''}`}>
                            <div className="post-header">
                                <Link to={`/blog/${post.id}`} className="date">{post.date}</Link>
                                <div className="post-header-icons">
                                    <Comments postId={post.id} isModal />
                                    <Likes postId={post.id} />
                                </div>
                            </div>
                            {post.imageUrl2 ? 
                            <div className="flex flex-gap images-wrapper">
                                <LazyLoad height={450} once>
                                    <Link to={`/blog/${post.id}`} className={`image-wrapper ${!post.horizontalImage ? "" : "horizontal-image"}  ${loadedImages[post.imageUrl1] ? 'loaded' : ''}`}>
                                        <img 
                                            src={post.imageUrl1} 
                                            alt={post.title} 
                                            className={`lazyload preload-image ${loadedImages[post.imageUrl1] ? 'loaded' : ''}`} 
                                            onLoad={() => handleLoad(post.imageUrl1)}
                                        />
                                        {!loadedImages[post.imageUrl1] && <div className="loader"></div>}
                                    </Link>
                                </LazyLoad>
                                <LazyLoad height={450} once>
                                    <Link to={`/blog/${post.id}`} className={`image-wrapper ${!post.horizontalImage ? "" : "horizontal-image"} ${loadedImages[post.imageUrl2] ? 'loaded' : ''}`}>
                                        <img 
                                            src={post.imageUrl2} 
                                            alt={post.title} 
                                            className={`lazyload preload-image ${loadedImages[post.imageUrl2] ? 'loaded' : ''}`} 
                                            onLoad={() => handleLoad(post.imageUrl2)} 
                                        />
                                        {!loadedImages[post.imageUrl2] && <div className="loader"></div>}
                                    </Link>
                                </LazyLoad>
                            </div> : post.imageUrl1 ?
                            <LazyLoad height={450} once>
                                <Link to={`/blog/${post.id}`} className={`image-wrapper ${!post.horizontalImage ? "" : "horizontal-image"} ${loadedImages[post.imageUrl1] ? 'loaded' : ''}`}>
                                    <img 
                                        src={post.imageUrl1} 
                                        alt={post.title} 
                                        className={`lazyload preload-image ${loadedImages[post.imageUrl1] ? 'loaded' : ''}`} 
                                        onLoad={() => handleLoad(post.imageUrl1)} 
                                    />
                                    {!loadedImages[post.imageUrl1] && <div className="loader"></div>}
                                </Link>
                            </LazyLoad> : <div className="no-image"></div>}
                            {/* <Link to={`/blog/${post.id}`} className="post-title">{post.title ?? ""} &#8594;</Link> */}
                            {/* <div className="post-content">
                                {parse(post.excerpt)}
                                <div className="tags">
                                    {post.tags && post.tags.map(tag => (
                                        <Link key={tag} to={`/tag/${tag}`} className="tag">
                                            #{tag}
                                        </Link>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default BlogList;
