import React from 'react';
import BlogList from './BlogList';
import Header from './../Header';
import Footer from './../Footer';

const BlogPage = ({ posts, toggleModal }) => {
  return (
    <div className="flex page-container">
      <Header toggleModal={toggleModal} />
      <BlogList posts={posts} />
      <Footer />
    </div>
  );
};

export default BlogPage;
