import React from 'react';
import { Link } from 'react-router-dom';
import yellow_flowers from '../yellow_flowers.jpg';

const BlogPage = () => {
  return (
    <div className="container homepage-container">
        <img src={yellow_flowers} className="App-logo" alt="logo" />
        <div className="content">
            <div>kia is making things in brooklyn.</div>
            <br />
            <div><Link to="/coaching">coaching &#8594;</Link></div>
            <div><Link to="/blog">blog &#8594;</Link></div>
        </div>
    </div>
  );
};

export default BlogPage;