// src/hooks/useAnalytics.js
import { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';

const useAnalytics = () => {
  const analytics = getAnalytics(); // Retrieve the Analytics instance

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, [analytics]);
};

export default useAnalytics;
