import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import allPosts from './data/blogPosts';
import BlogPost from './BlogPost';

const TagPage = () => {
  const { tagName } = useParams();
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setPosts(allPosts.filter((post) => {
        return post.tags.includes(tagName)
  }));
  }, [allPosts, tagName]);

  return (
    <div className="tag-page-container">
      <Link to="#" onClick={() => navigate(-1)} className="back-button block">&#8592; Back</Link>
      <h2>#{tagName}</h2>
      {posts.length > 0 ? (
        posts.map(post => (
          <BlogPost postId={post.id} />
        ))
      ) : (
        <p className="text-gray">No posts found with this tag.</p>
      )}
    </div>
  );
};

export default TagPage;
