import React from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const SignOutButton = ({ onSignOut }) => {
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      onSignOut(true);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <a onClick={handleSignOut} className="sign-in-link">
      sign out
    </a>
  );
};

export default SignOutButton;
