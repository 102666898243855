// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBROkr_-ez3pJRuGSWptvH8k62rr23ULE8",
    authDomain: "my-blog-be300.firebaseapp.com",
    projectId: "my-blog-be300",
    storageBucket: "my-blog-be300.appspot.com",
    messagingSenderId: "514463509516",
    appId: "1:514463509516:web:346147338f151a75e1d229",
    measurementId: "G-NNNGX6RE3E"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Google Analytics, Auth, Firestore, Storage
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, db, firebaseConfig, storage };
