import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from './../firebase';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, {
        name: name,
      });

      console.log('User signed up:', user);
      setSuccess("Account created");
      navigate("/blog");
    } catch (error) {
        setError(error.message);
      console.error('Error signing up:', error);
      // Handle signup errors (e.g., display error message)
    } finally {
        setLoading(false);
      }
  };

  return (
    <div className="sign-in-container container flex-col">
      <h2 className="flex reddit-mono-400">sign up</h2>
        <form onSubmit={handleSignup}>
            <div className="flex mb-1">
                <input
                  type="name"
                  value={name}
                  placeholder="name"
                  className="flex reddit-mono-400 w-full"
                  onChange={(e) => setName(e.target.value)}
                  required />
            </div>
            <div className="flex mb-1">
                <input
                  type="email"
                  value={email}
                  placeholder="email"
                  className="flex reddit-mono-400 w-full"
                  onChange={(e) => setEmail(e.target.value)}
                  required />
            </div>
            <div className="flex mb-1">
                <input
                    type="password"
                    value={password}
                    placeholder="password"
                    className="flex reddit-mono-400 w-full"
                    onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button type="submit" disabled={loading} className="reddit-mono-400 w-full">
          {loading ? 'signing up...' : 'sign up'}
        </button>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{error}</p>}
        </form>
        <Link to="/blog" className="sign-in-back-link">&#8592; Back home</Link>
    </div>
  );
};

export default Signup;
