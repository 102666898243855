// components/BlogPostForm.js
import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const BlogPostForm = () => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image1) {
      setMessage('Please select the first image');
      return;
    }

    const tagsArray = tags.split(',').map(tag => tag.trim());

    const image1Ref = ref(storage, `images/${image1.name}`);
    await uploadBytes(image1Ref, image1);
    const imageUrl1 = await getDownloadURL(image1Ref);

    let imageUrl2 = '';
    if (image2) {
      const image2Ref = ref(storage, `images/${image2.name}`);
      await uploadBytes(image2Ref, image2);
      imageUrl2 = await getDownloadURL(image2Ref);
    }

    try {
      await addDoc(collection(db, 'blogPosts'), {
        title,
        date,
        excerpt,
        content,
        tags: tagsArray,
        imageUrl1,
        imageUrl2,
        createdAt: new Date()
      });
      setTitle('');
      setDate('');
      setExcerpt('');
      setContent('');
      setTags('');
      setImage1(null);
      setImage2(null);
      setMessage('Post added successfully!');
    } catch (error) {
      console.error('Error adding document: ', error);
      setMessage('Error adding post, please try again.');
    }
  };

  // Restrict access to authenticated users
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate.push('/login');
      }
    });

    return unsubscribe;
  }, [navigate]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Title:</label>
        <input 
          type="text" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          required 
        />
      </div>
      <div>
        <label>Date:</label>
        <input 
          type="date" 
          value={date} 
          onChange={(e) => setDate(e.target.value)} 
          required 
        />
      </div>
      <div>
        <label>Excerpt:</label>
        <textarea 
          value={excerpt} 
          onChange={(e) => setExcerpt(e.target.value)} 
          required 
        />
      </div>
      <div>
        <label>Content:</label>
        <textarea 
          value={content} 
          onChange={(e) => setContent(e.target.value)} 
          required 
        />
      </div>
      <div>
        <label>Tags (comma separated):</label>
        <input 
          type="text" 
          value={tags} 
          onChange={(e) => setTags(e.target.value)} 
          required 
        />
      </div>
      <div>
        <label>Image 1:</label>
        <input 
          type="file" 
          onChange={(e) => setImage1(e.target.files[0])} 
          required 
        />
      </div>
      <div>
        <label>Image 2 (optional):</label>
        <input 
          type="file" 
          onChange={(e) => setImage2(e.target.files[0])} 
        />
      </div>
      <button type="submit">Submit</button>
      {message && <p>{message}</p>}
    </form>
  );
};

export default BlogPostForm;
