import React from 'react';

const Footer = () => {
  return (
    <footer className="reddit-mono-400">
      <div>code, design, & photos by kia</div>
    </footer>
  );
};

export default Footer;