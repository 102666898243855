import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the import path as needed
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignInWithEmailAndPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      // User signed in successfully
      setSuccess("Account created");
      navigate(-1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sign-in-container container flex-col">
      <h2 className="flex reddit-mono-400">sign in</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="flex mb-1">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex reddit-mono-400 w-full"
            required
          />
        </div>
        <div className="flex mb-1">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="flex reddit-mono-400 w-full"
            required
          />
        </div>
        <button type="submit" disabled={loading} className="reddit-mono-400 w-full">
          {loading ? 'signing in...' : 'sign in'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
        {success && <p className="success">{error}</p>}
        <Link to="/sign-up">Create an account</Link>
      <Link to="#" onClick={() => navigate(-1)} className="sign-in-back-link">&#8592; Back</Link>
    </div>
  );
};

export default SignInWithEmailAndPassword;
