import React from 'react';
import { useParams } from 'react-router-dom';
import allPosts from './data/blogPosts';
import Likes from './Likes';
import Comments from './Comments';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from './../Header';
import Footer from './../Footer';

const BlogPost = ({ postId: passedInId, toggleModal }) => {
  const { postId } = useParams();
  const id = passedInId ?? postId;
  const post = allPosts.find(post => post.id === id);

  if (!post) {
    return <p>Post not found.</p>;
  }

  return (
    <>
      <Header toggleModal={toggleModal} />
      <div className="blog-post-container">
        <div className="post">
          <div className="post-header">
              <Link to={`/blog/${post.id}`} className="date">{post.date}</Link>
              <div className="post-header-icons">
                <Comments postId={post.id} isModal />
                <Likes postId={post.id} />
              </div>
          </div>
          {/* {post.imageUrl2 ? 
            <div className="flex flex-gap">
              <img src={post.imageUrl1} alt={post.title} />
              <img src={post.imageUrl2} alt={post.title} />
            </div> : <img src={post.imageUrl1} alt={post.title} />} */}
          {post.imageUrl2 ? 
            <div className="flex flex-gap images-wrapper">
              <img 
                  src={post.imageUrl1} 
                  alt={post.title} />
              <img 
                  src={post.imageUrl2} 
                  alt={post.title} />
            </div> : post.imageUrl1 ?
              <img 
                  src={post.imageUrl1} 
                  alt={post.title} /> : <div className="no-image"></div>}
          {/* <Link to={`/blog/${post.id}`} className="post-title">{post.title ?? ""} &#8594;</Link> */}
          {/* <div className="post-content">
            {parse(post.content)}
            <div className="tags">
                {post.tags && post.tags.map(tag => (
                    <Link key={tag} to={`/tag/${tag}`} className="tag">
                        #{tag}
                    </Link>
                ))}
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPost;


